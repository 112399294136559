


























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';

@Component({
  components: { SygniRectButton }
})
export default class MarketplaceModule extends Vue {
  get activeProduct() {
    return this.$store.getters['investors/newProduct']
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get annexingProducts() {
    return this.$store.getters['investors/annexingProducts']
  }

  get mode() {
    return this.$route.params?.id ? 'edit' : 'add'
  }

  get isLlc() {
    return this.activeUserData?.equityType !== 'JOINT_STOCK_SHARES'
  }

  getProductLink(id: string) {
    return this.$route.path.includes('company') ? `/company/capital-rise/product/summary/${id}/for-legal-entity` : `/fund/capital-rise/product/summary/${id}/for-legal-entity`
  }

  goBack() {
    if (this.$route.name === 'addNewProductStepTwo') {
      if (this.activeProduct?.subscriptionId) {
        this.$router.push({ name: 'addNewProductStepOne', path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise/product/new/step-one`, params: { subscriptionId: this.activeProduct.subscriptionId, productType: this.isLlc ? 'LLC_SHARES' : 'JOINT_STOCK_SHARES' } })
      } else {
        this.$router.go(-1)
      }
    } else if (this.$route.name === 'addNewProductStepOne') {
      if (this.activeProduct?.subscriptionId) {
        this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise?page=subscriptions` })
      } else {
        if (this.activeProduct?.investmentClientId && this.activeProduct?.investorLegalEntityId) {
          this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise` })
        } else {
          this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise?page=products` })
        }
      }
    } else {
      this.$router.go(-1)
    }
  }
}
