var render = function () {
  var _vm$annexingProducts, _vm$activeProduct, _vm$activeProduct2, _vm$activeProduct3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['add-new-product', _vm.$route.name == 'addNewProductSuccess' ? 'margin-top' : 0]
  }, [_vm.$route.name != 'addNewProductSuccess' ? _c('div', {
    staticClass: "add-new-product__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "outline black",
    attrs: {
      "arrowIconLeft": true
    },
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v("Go back")])], 1) : _vm._e(), (_vm$annexingProducts = _vm.annexingProducts) !== null && _vm$annexingProducts !== void 0 && _vm$annexingProducts.length && (_vm$activeProduct = _vm.activeProduct) !== null && _vm$activeProduct !== void 0 && _vm$activeProduct.annexType && _vm.$route.name !== 'addNewProductSuccess' ? _c('div', {
    staticClass: "infobox"
  }, [_c('p', {
    staticClass: "infobox__label"
  }, [_vm.mode === 'edit' ? [_vm._v(" This is " + _vm._s(((_vm$activeProduct2 = _vm.activeProduct) === null || _vm$activeProduct2 === void 0 ? void 0 : _vm$activeProduct2.annexType) === 'annex' ? 'an annex' : 'a roll') + " of: ")] : _vm._e(), _vm.mode === 'add' ? [_vm._v(" You are currently " + _vm._s(((_vm$activeProduct3 = _vm.activeProduct) === null || _vm$activeProduct3 === void 0 ? void 0 : _vm$activeProduct3.annexType) === 'annex' ? 'annexing' : 'rolling') + ": ")] : _vm._e()], 2), _c('ul', {
    staticClass: "infobox__list"
  }, _vm._l(_vm.annexingProducts, function (product) {
    return _c('li', {
      key: product.id
    }, [_c('router-link', {
      attrs: {
        "to": _vm.getProductLink(product.id),
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(product.code))])], 1);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "add-new-product__box"
  }, [_c('router-view')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }